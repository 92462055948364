import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Upload from './pages/Upload';
import PrivateRoute from './components/PrivateRoute';
import Download from './pages/Download';
import 'antd/dist/reset.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/upload" 
          element={
            <PrivateRoute>
              <Upload />
            </PrivateRoute>
          } 
        />
        <Route path="/download/:folderId" element={<Download />} />
        <Route path="/" element={<Navigate to="/upload" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App; 