import React, { useState, useEffect } from 'react';
import { 
  Table, Button, Input, Modal, Form, 
  message, Space, Upload, Select, Collapse
} from 'antd';
import { 
  PlusOutlined, DeleteOutlined, EditOutlined,
  UploadOutlined, DownloadOutlined, QuestionCircleOutlined
} from '@ant-design/icons';
import api from '../utils/api';  // 使用统一的 api 实例

const { TextArea } = Input;
const { Option } = Select;
const { Panel } = Collapse;

const RandomComments = () => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingComment, setEditingComment] = useState(null);
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [batchDeleting, setBatchDeleting] = useState(false);

  // 获取评论列表
  const fetchComments = async () => {
    setLoading(true);
    try {
      const response = await api.get('/random-comments');
      setComments(response.data);
    } catch (error) {
      console.error('Failed to load comments:', error);
      message.error('获取评论列表失败');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComments();
  }, []);

  // 添加或更新评论
  const handleSubmit = async (values) => {
    try {
      if (editingComment) {
        await api.put(`/random-comments/${editingComment.id}`, values);
        message.success('更新评论成功');
      } else {
        await api.post('/random-comments', values);
        message.success('添加评论成功');
      }
      setModalVisible(false);
      form.resetFields();
      setEditingComment(null);
      fetchComments();
    } catch (error) {
      console.error('Operation failed:', error);
      message.error('操作失败');
    }
  };

  // 删除评论
  const handleDelete = async (id) => {
    try {
      await api.delete(`/random-comments/${id}`);
      message.success('删除成功');
      fetchComments();
    } catch (error) {
      console.error('Delete failed:', error);
      message.error('删除失败');
    }
  };

  // 导出评论
  const handleExport = () => {
    const content = comments
      .map(comment => `${comment.category},${comment.content}`)
      .join('\n');
    
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `随机评论_${new Date().toLocaleDateString()}.txt`;
    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // 导入评论
  const handleImport = async (file) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        // 按行分割，并解析每行的分类和评论内容
        const comments = content.split('\n')
          .map(line => line.trim())
          .filter(line => line)
          .map(line => {
            const [category = '默认', ...contentParts] = line.split(',');
            const content = contentParts.join(',');
            return {
              category: category.trim(),
              content: content.trim()
            };
          })
          .filter(comment => comment.content);
        
        if (comments.length === 0) {
          message.error('文件内容为空');
          return;
        }

        // 验证分类
        const validCategories = ['好评', '中评', '差评', '默认'];
        const invalidComments = comments.filter(
          comment => !validCategories.includes(comment.category)
        );

        if (invalidComments.length > 0) {
          message.error('存在无效的评论分类，请检查导入文件');
          return;
        }

        await api.post('/random-comments/batch', { comments });
        message.success(`成功导入 ${comments.length} 条评论`);
        fetchComments();
      };
      reader.readAsText(file, 'UTF-8');
    } catch (error) {
      console.error('Import failed:', error);
      message.error('导入失败');
    }
    return false;
  };

  // 下载评论模板
  const handleDownloadTemplate = async () => {
    try {
      const response = await api.get('/random-comments/template', {
        responseType: 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.download = '随机评论模板.txt';
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download template failed:', error);
      message.error('下载模板失败');
    }
  };

  // 新增：批量删除方法
  const handleBatchDelete = async () => {
    if (!selectedRowKeys.length) {
      message.warning('请选择要删除的评论');
      return;
    }

    Modal.confirm({
      title: '确认删除',
      content: `确定要删除选中的 ${selectedRowKeys.length} 条评论吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        try {
          setBatchDeleting(true);
          await api.post('/random-comments/batch-delete', {
            ids: selectedRowKeys
          });
          message.success('批量删除成功');
          setSelectedRowKeys([]); // 清空选中
          fetchComments();
        } catch (error) {
          console.error('批量删除失败:', error);
          message.error('批量删除失败');
        } finally {
          setBatchDeleting(false);
        }
      }
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
    },
    {
      title: '内容',
      dataIndex: 'content',
      ellipsis: true,
    },
    {
      title: '分类',
      dataIndex: 'category',
      width: 120,
    },
    {
      title: '创建时间',
      dataIndex: 'created_at',
      width: 180,
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: '操作',
      width: 150,
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditingComment(record);
              form.setFieldsValue(record);
              setModalVisible(true);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  // 表格行选择配置
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    }
  };

  return (
    <div style={{ padding: '24px' }}>
      <div style={{ marginBottom: 16 }}>
        {/* 功能按钮组 */}
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setEditingComment(null);
              form.resetFields();
              setModalVisible(true);
            }}
          >
            添加评论
          </Button>
          <Upload
            accept=".txt"
            showUploadList={false}
            beforeUpload={handleImport}
          >
            <Button icon={<UploadOutlined />}>导入评论</Button>
          </Upload>
          <Button
            icon={<DownloadOutlined />}
            onClick={handleExport}
          >
            导出评论
          </Button>
          <Button
            icon={<DownloadOutlined />}
            onClick={handleDownloadTemplate}
          >
            下载模板
          </Button>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={handleBatchDelete}
            disabled={!selectedRowKeys.length}
            loading={batchDeleting}
          >
            批量删除
          </Button>
        </Space>

        {/* 使用说明 - 折叠面板 */}
        <Collapse 
          ghost 
          style={{ marginTop: '8px' }}
        >
          <Panel 
            header={
              <Space>
                <QuestionCircleOutlined />
                <span>随机评论使用说明</span>
              </Space>
            } 
            key="1"
          >
            <div style={{ 
              background: '#f5f5f5', 
              padding: '12px', 
              borderRadius: '4px',
              fontSize: '13px'
            }}>
              <ul style={{ margin: 0, paddingLeft: '20px' }}>
                <li>
                  <strong>主要用途：</strong>
                  当系统找不到评论文件时，自动提供一条随机评论作为替代内容
                </li>
                <li>
                  <strong>适用场景：</strong>
                  商品评价缺失、评论文件丢失或空白评论需要填充时
                </li>
                <li>
                  <strong>使用建议：</strong>
                  添加多样化的评论内容，可按好评、中评、差评分类管理，并定期更新保持新鲜度
                </li>
              </ul>
            </div>
          </Panel>
        </Collapse>

        {/* 导入格式说明 */}
        <div style={{ 
          fontSize: '12px', 
          color: '#888',
          marginTop: '4px',
          marginLeft: '4px'
        }}>
          导入格式：每行一条评论，格式为"分类,评论内容"（例如："好评,很好的商品"）
        </div>
      </div>

      {/* 表格部分 */}
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={comments}
        rowKey="id"
        loading={loading}
        pagination={{
          showSizeChanger: true,
          showTotal: (total) => `共 ${total} 条`,
        }}
      />

      <Modal
        title={editingComment ? '编辑评论' : '添加评论'}
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          setEditingComment(null);
          form.resetFields();
        }}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="content"
            label="评论内容"
            rules={[{ required: true, message: '请输入评论内容' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="category"
            label="分类"
            rules={[{ required: true, message: '请选择分类' }]}
          >
            <Select>
              <Option value="默认">默认评论</Option>
              <Option value="好评">好评</Option>
              <Option value="差评">差评</Option>
              <Option value="中评">中评</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RandomComments; 