import axios from 'axios';
import { message } from 'antd';
import config from '../config/config';
import auth from './auth';

const api = axios.create({
  baseURL: config.API_BASE_URL,
  timeout: 1800000, // 30分钟 = 30 * 60 * 1000 毫秒
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  config => {
    // 只保留 token 相关的逻辑
    const token = auth.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    // 移除 pendingRequests 相关的代码，直接返回 response
    return response;
  },
  async error => {
    // 移除 cancelToken 相关的检查
    if (error.response?.status === 401) {
      // 如果是登录请求失败，直接返回错误
      if (error.config.url.includes('/auth/login')) {
        message.error('用户名或密码错误');
        return Promise.reject(error);
      }

      // 清除认证信息
      auth.clearAuth();

      // 如果在登录页面，不需要跳转
      if (window.location.pathname === '/login') {
        return Promise.reject(error);
      }

      // 保存当前页面路径
      const currentPath = window.location.pathname;
      message.error('登录已过期，请重新登录');
      
      // 跳转到登录页面，并携带重定向信息
      window.location.href = `/login?redirect=${encodeURIComponent(currentPath)}`;
      return Promise.reject(error);
    }

    // 处理其他错误
    const errorMsg = error.response?.data?.error || '请求失败';
    message.error(errorMsg);
    return Promise.reject(error);
  }
);

export default api; 