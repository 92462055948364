import React, { useState, useEffect } from 'react';
import { Layout, message } from 'antd';
import { useParams } from 'react-router-dom';
import downloadService from '../services/downloadService';
import { isMobile, isIOS, isAndroid } from '../utils/deviceDetect';

const { Content } = Layout;

const Download = () => {
  const { folderId } = useParams();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState({ images: [], videos: [], texts: [] });
  const [isPWA, setIsPWA] = useState(false);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const checkPWA = () => {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches ||
                          window.navigator.standalone || // iOS
                          document.referrer.includes('android-app://');
      setIsPWA(isStandalone);
    };

    checkPWA();
    loadFiles();

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallPrompt(true);
    });

    window.matchMedia('(display-mode: standalone)').addListener(checkPWA);

    return () => {
      window.matchMedia('(display-mode: standalone)').removeListener(checkPWA);
    };
  }, [folderId]);

  const loadFiles = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const { data } = await downloadService.getFolderContents(folderId);
      setFiles({
        images: data.files.filter(f => f.fileType === 'image'),
        videos: data.files.filter(f => f.fileType === 'video'),
        texts: data.textFiles || []
      });
    } catch (error) {
      console.error('Load failed:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInstallPWA = async () => {
    if (!deferredPrompt) return;
    
    try {
      const result = await deferredPrompt.prompt();
      console.log(`Installation ${result.outcome}`);
      setDeferredPrompt(null);
      setShowInstallPrompt(false);
    } catch (error) {
      console.error('PWA installation failed:', error);
    }
  };

  const downloadAllMedia = async () => {
    if (isMobile && !isPWA) {
      message.info('请先将应用添加到主屏幕以获得完整功能');
      return;
    }

    let successCount = 0;
    const allMedia = [...files.images, ...files.videos];
    
    if (isIOS) {
      try {
        const shareFiles = await Promise.all(
          allMedia.map(async file => {
            const { data } = await downloadService.downloadFile(file.url);
            return new File([data], file.name, { type: data.type });
          })
        );

        if (navigator.share) {
          await navigator.share({
            files: shareFiles,
            title: '保存媒体文件',
          });
          message.success('请在分享菜单中选择"保存到相册"');
        } else {
          message.info('请长按图片或视频进行保存');
        }
      } catch (error) {
        console.error('Share failed:', error);
        message.error('分享失败，请手动保存');
      }
    } else {
      for (const file of allMedia) {
        try {
          const { data } = await downloadService.downloadFile(file.url);
          
          if (isAndroid && isPWA) {
            try {
              const handle = await window.showSaveFilePicker({
                suggestedName: file.name,
                types: [{
                  description: 'Media Files',
                  accept: {
                    'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
                    'video/*': ['.mp4', '.webm']
                  }
                }]
              });
              const writable = await handle.createWritable();
              await writable.write(data);
              await writable.close();
              successCount++;
            } catch (e) {
              const url = window.URL.createObjectURL(data);
              const a = document.createElement('a');
              a.href = url;
              a.download = file.name;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              successCount++;
            }
          } else {
            const url = window.URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            successCount++;
          }
        } catch (error) {
          console.error('Download failed:', error);
        }
      }

      if (successCount > 0) {
        message.success(`已保存 ${successCount}/${allMedia.length} 个文件`);
      }
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ padding: '24px' }}>
        <div className="container">
          {showInstallPrompt && (
            <div className="install-prompt">
              <p>安装到主屏幕以获得更好的下载体验</p>
              <button onClick={handleInstallPWA}>安装应用</button>
              <button onClick={() => setShowInstallPrompt(false)}>暂不安装</button>
            </div>
          )}

          {files.texts.length > 0 && (
            <div className="comments-section">
              <div className="comments-header">评论</div>
              {files.texts.map((text, index) => (
                <div key={index} className="comment-item">
                  <div className="comment-content">{text.content}</div>
                  <div 
                    className="copy-icon" 
                    onClick={() => {
                      navigator.clipboard.writeText(text.content);
                      message.success('已复制到剪贴板');
                    }}
                  >
                    复制
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="image-grid">
            {[...files.images, ...files.videos].map((file, index) => (
              <div key={index} className="image-wrapper">
                {file.fileType === 'video' ? (
                  <video controls preload="metadata" muted>
                    <source src={file.url} type={file.type} />
                  </video>
                ) : (
                  <img src={file.url} alt={file.name} loading="lazy" />
                )}
              </div>
            ))}
          </div>

          <button 
            className="download-btn" 
            onClick={downloadAllMedia}
            disabled={loading || files.images.length + files.videos.length === 0}
          >
            {isIOS ? '保存到相册' : '一键下载'}
          </button>
        </div>
      </Content>
    </Layout>
  );
};

export default Download; 