import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../utils/api';
import auth from '../utils/auth';
import config from '../config/config';
import './Login.css';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // 页面加载时获取存储的登录信息
    const rememberedUser = localStorage.getItem('rememberedUser');
    if (rememberedUser) {
      const { email, password, remember } = JSON.parse(rememberedUser);
      form.setFieldsValue({ email, password, remember });
    }
  }, [form]);

  const onFinish = async (values) => {
    const { email, password, remember } = values;
    setLoading(true);
    
    try {
      const { data } = await api.post('/auth/login', { email, password });
      
      // 处理记住密码
      if (remember) {
        localStorage.setItem('rememberedUser', JSON.stringify({ email, password, remember }));
      } else {
        localStorage.removeItem('rememberedUser');
      }

      auth.setAuth(data.token, data.user);
      message.success('登录成功');
      
      const redirect = new URLSearchParams(location.search).get('redirect') || '/upload';
      navigate(redirect);
    } catch (error) {
      console.error('Login failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="decoration-container">
        <div className="decoration-item top-left"></div>
        <div className="decoration-item top-right"></div>
        <div className="decoration-item bottom-left"></div>
        <div className="decoration-item bottom-right"></div>
      </div>

      <div className="login-content">
        <div className="login-left">
          <h2 className="welcome-text">欢迎使用</h2>
          <h1 className="platform-name">{config.APP_NAME}</h1>
          <p className="platform-desc">图片评论，云端共享，一键下载相册</p>
          <div className="feature-list">
            <div className="feature-item">
              <i className="fas fa-cloud-upload-alt"></i>
              <span>快速上传</span>
            </div>
            <div className="feature-item">
              <i className="fas fa-shield-alt"></i>
              <span>数据独立</span>
            </div>
            <div className="feature-item">
              <i className="fas fa-share-alt"></i>
              <span>便捷分享</span>
            </div>
            <div className="feature-item">
              <i className="fas fa-database"></i>
              <span>安全存储</span>
            </div>
            <div className="feature-item">
              <i className="fas fa-download"></i>
              <span>一键下载</span>
            </div>
            <div className="feature-item">
              <i className="fas fa-user-shield"></i>
              <span>隐私保护</span>
            </div>
          </div>
        </div>

        <Card className="login-card">
          <div className="login-header">
            <div className="logo-container">
              <div className="logo-circle">
                <img src="/logo.svg" alt="logo" className="logo-image" />
              </div>
            </div>
            <h1>{config.APP_NAME}</h1>
            <p className="subtitle">欢迎回来，请登录您的账号</p>
          </div>

          <Form
            form={form}
            name="login"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            autoComplete="off"
            size="large"
            className="login-form"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: '请输入账户名' }
              ]}
            >
              <Input 
                prefix={<UserOutlined className="input-icon" />} 
                placeholder="账户名" 
                disabled={loading}
                className="login-input"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: '请输入密码' }]}
            >
              <Input.Password
                prefix={<LockOutlined className="input-icon" />}
                placeholder="密码"
                disabled={loading}
                className="login-input"
              />
            </Form.Item>

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>记住密码</Checkbox>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading}
                block
                className="login-button"
              >
                登录
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Login;