import React, { useState } from 'react';
import './ExportDialog.css';

const ExportDialog = ({ onExport, onClose }) => {
  const [includeFolderName, setIncludeFolderName] = useState(false);
  
  return (
    <div className="export-dialog-overlay">
      <div className="export-dialog">
        <h3>导出选项</h3>
        <div className="export-option">
          <label>
            <input
              type="checkbox"
              checked={includeFolderName}
              onChange={(e) => setIncludeFolderName(e.target.checked)}
            />
            包含文件夹名称
          </label>
        </div>
        <div className="export-dialog-buttons">
          <button 
            className="export-btn"
            onClick={() => onExport(includeFolderName)}
          >
            导出
          </button>
          <button 
            className="cancel-btn"
            onClick={onClose}
          >
            取消
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExportDialog; 