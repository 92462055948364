import React, { useState, useEffect } from 'react';
import { Layout, Menu, Button, Space, Tabs } from 'antd';
import { LogoutOutlined, UserOutlined, TeamOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UploadArea from '../components/UploadArea';
import UserManagement from '../components/UserManagement';
import RandomComments from '../components/RandomComments';
import config from '../config/config';

const { Header, Content } = Layout;

const Upload = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const isAdmin = user.isAdmin === true;
  
  const [showUserManagement, setShowUserManagement] = useState(
    searchParams.get('tab') === 'users'
  );

  const handleTabChange = (showUsers) => {
    setShowUserManagement(showUsers);
    setSearchParams(showUsers ? { tab: 'users' } : {});
  };

  useEffect(() => {
    setShowUserManagement(searchParams.get('tab') === 'users');
  }, [searchParams]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 24px',
        background: '#fff',
        height: '64px',
        lineHeight: '64px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        width: '100%',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
      }}>
        <h2 style={{ margin: 0 }}>{config.APP_NAME}</h2>
        <Space size="middle">
          <span>
            <UserOutlined /> {user.username}
          </span>
          {isAdmin && (
            <Button 
              icon={<SettingOutlined />}
              onClick={() => handleTabChange(!showUserManagement)}
              type="primary"
              ghost
            >
              {showUserManagement ? '返回上传' : '系统管理'}
            </Button>
          )}
          <Button 
            icon={<LogoutOutlined />} 
            onClick={handleLogout}
          >
            退出登录
          </Button>
        </Space>
      </Header>
      <Content style={{ 
        padding: '14px',
        margin: '14px',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        overflow: 'auto',
        height: 'calc(100vh - 112px)'
      }}>
        {showUserManagement ? (
          <Tabs
            defaultActiveKey="users"
            items={[
              {
                key: 'users',
                label: '用户管理',
                children: <UserManagement />,
              },
              {
                key: 'comments',
                label: '随机评论',
                children: <RandomComments />,
              },
            ]}
          />
        ) : (
          <UploadArea />
        )}
      </Content>
    </Layout>
  );
};

export default Upload; 