import api from '../utils/api';

const downloadService = {
  /**
   * 获取文件夹内容
   * @param {string} folderId - 文件夹ID
   * @returns {Promise<{files: Array, textFiles: Array}>}
   */
  getFolderContents: (folderId) => {
    return api.get(`/download/images/${folderId}`);
  },

  /**
   * 下载单个文件
   * @param {string} fileUrl - 文件URL
   * @returns {Promise<Blob>}
   */
  downloadFile: (fileUrl) => {
    return api.get(fileUrl, {
      responseType: 'blob',
      // 跳过重复请求检查，因为下载多个文件时会同时发起多个请求
      skipDuplicateCheck: true
    });
  }
};

export default downloadService; 