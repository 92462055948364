const config = {
  development: {
    API_BASE_URL: process.env.REACT_APP_SERVER_PORT || 'http://localhost:3000/api',
  },
  production: {
    API_BASE_URL: process.env.REACT_APP_SERVER_PORT || '/api',
  },
  test: {
    API_BASE_URL: process.env.REACT_APP_SERVER_PORT || 'http://test-api.example.com/api',
  }
};

const env = process.env.NODE_ENV || 'development';

// 添加一些辅助函数
const isDevelopment = env === 'development';
const isProduction = env === 'production';
const isTest = env === 'test';

export default {
  ...config[env],
  isDevelopment,
  isProduction,
  isTest,
  // 其他全局配置
  APP_NAME: '图评云享',
  FILE_SIZE_LIMIT: 100 * 1024 * 1024, // 100MB
  ALLOWED_FILE_TYPES: ['image/jpeg', 'image/png', 'application/pdf'],
  UPLOAD_BATCH_SIZE: 5,
  TOKEN_KEY: 'token',
  USER_KEY: 'user',
}; 