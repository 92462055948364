import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import auth from '../utils/auth';

const PrivateRoute = ({ children }) => {
  const location = useLocation();

  if (!auth.isAuthenticated()) {
    // 将当前路径作为重定向参数
    return <Navigate 
      to={`/login?redirect=${encodeURIComponent(location.pathname)}`} 
      replace 
    />;
  }

  return children;
};

export default PrivateRoute; 