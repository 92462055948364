import React from 'react';
import './UploadQueue.css';

const UploadQueue = ({ queue, progress }) => {
  if (queue.length === 0) return null;

  return (
    <div className="upload-queue">
      <h3>上传队列</h3>
      {queue.map(upload => (
        <div key={upload.id} className={`queue-item ${upload.status}`}>
          <div className="queue-item-info">
            <span className="folder-name">{upload.name}</span>
            <span className="status-text">
              {upload.status === 'pending' && '等待中...'}
              {upload.status === 'uploading' && 
                `上传中... ${Math.round(progress[upload.id] || 0)}%`}
              {upload.status === 'completed' && '已完成'}
              {upload.status === 'error' && `上传失败: ${upload.error || '未知错误'}`}
            </span>
          </div>
          {upload.status === 'uploading' && (
            <div className="progress-bar">
              <div 
                className="progress" 
                style={{width: `${progress[upload.id] || 0}%`}}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default UploadQueue; 