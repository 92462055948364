import config from '../config/config';

const auth = {
  getToken() {
    return localStorage.getItem(config.TOKEN_KEY);
  },

  getUser() {
    const userStr = localStorage.getItem(config.USER_KEY);
    return userStr ? JSON.parse(userStr) : null;
  },

  setAuth(token, user) {
    localStorage.setItem(config.TOKEN_KEY, token);
    localStorage.setItem(config.USER_KEY, JSON.stringify(user));
  },

  clearAuth() {
    localStorage.removeItem(config.TOKEN_KEY);
    localStorage.removeItem(config.USER_KEY);
  },

  isAuthenticated() {
    return !!this.getToken();
  }
};

export default auth; 